import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import manger from "../views/manger.vue"
import Live from '../views/Live.vue'
import Produce from "../views/Produce.vue"
import Tea from "../views/Tea.vue"
import Agriculture from "../views/Agriculture.vue"
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/manger",
    name: "manger",
    component: manger,
    children:[
      {
        path:'/',
        component:Produce
      },
      {
        path:'live',
        component:Live
      },
      {
        path:'produce',
        component:Produce
      },
      {
        path:'tea',
        component:Tea
      },
      {
        path:'agriculture',
        component:Agriculture
      }
    ]
  },
  
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

export default router;
