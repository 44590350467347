import Vue from 'vue'
import axios from 'axios'
import router from "../../router"
import { Message } from 'element-ui'
// import store from '../../store'
//  import qs from 'qs'
 

let token = ''
// 响应时间
axios.defaults.timeout = 5 * 1000
// 配置cookie
// axios.defaults.withCredentials = true
// 配置cookie
// axios.defaults.headers.common['Authorization '] = token
// 配置请求头
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8'
// axios.defaults.headers.post['Content-Type'] = 'application/json'
// 静态资源
Vue.prototype.$static = ''
// 配置接口地址
//  axios.defaults.baseURL = 'http://192.168.1.176:8080/'
axios.defaults.baseURL = 'https://admin.anding.forkon.com.cn'
/* let loadingInstance */
// POST传参序列化(添加请求拦截器)
axios.interceptors.request.use(config => {
  // if (config.method === 'post') {
  //   config.data = qs.stringify(config.data)
  // }
  //  let useinfo = window.sessionStorage.getItem('_evgetinfo1')
  // if (useinfo) {
  //   token = boost.Boost(useinfo)
  // }
  token=sessionStorage.getItem('TOKEN');
  config.headers.common['Authorization'] =token;
  //请求头转换为表单形式
  config.headers['Content-Type'] = 'application/json; charset=UTF-8'
  // config.transformRequest = [function (data) {
  //   // 在请求之前对data传参进行格式转换
  //   data = qs.stringify(data)
  //   return data
  // }]
  return config
}, err => {
  // Message.error('请求错误!');
  return Promise.reject(err)
})
// 返回状态判断(添加响应拦截器)
axios.interceptors.response.use(res => {
  if (res.data.code === 0||res.data.code === 200) {
    return res
  } else if (res.data.code==1||res.data.code==401){
    localStorage.clear();
    Message.error(res.data.msg);
    sessionStorage.clear();
   router.push('/');
  }
  else {
   Message.error(res.data.msg);
    /* loadingInstance.close() */
    // this.$message.error(res.data.msg)
  }
}, err => {
   // Message.error('请求失败, 请稍后再试!');
  return Promise.reject(err)
})
// post请求
export function post (url, params) {
  return new Promise((resolve, reject) => {
    axios
      .post(url, params)
      .then(res => {
        resolve(res.data)
      }, err => {
        reject(err.data)
      })
      .catch(err => {
        reject(err.data)
      })
  })
}
// get请求
export function get (url, params) {
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        params: params
      })
      .then(res => {
        resolve(res.data)
      })
      .catch(err => {
        reject(err.data)
      })
  })
}
// put请求
export function put (url, params) {
  return new Promise((resolve, reject) => {
    axios
      .put(url, params)
      .then(res => {
        resolve(res.data)
      }, err => {
        reject(err.data)
      })
      .catch(err => {
        reject(err.data)
      })
  })
}

// delete请求
export function cut (url, params) {
  return new Promise((resolve, reject) => {
    axios
      .delete(url, params)
      .then(res => {
        resolve(res.data)
      }, err => {
        reject(err.data)
      })
      .catch(err => {
        reject(err.data)
      })
  })
}