var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"live"},[_c('div',{staticClass:"nav"},[_vm._v("生长视频")]),_c('div',{staticClass:"vd"},[_c('el-form',{ref:"form",attrs:{"model":_vm.form,"label-width":"130px"}},[_c('el-form-item',{attrs:{"label":"选择展示类型：","rules":[
          {
            required: true,
            trigger: 'blur',
          } ]}},[_c('el-select',{attrs:{"placeholder":"请选择展示类型"},model:{value:(_vm.form.type),callback:function ($$v) {_vm.$set(_vm.form, "type", $$v)},expression:"form.type"}},[_c('el-option',{attrs:{"label":"视频","value":"视频"}}),_c('el-option',{attrs:{"label":"直播","value":"直播"}})],1)],1),(_vm.form.type == '视频')?_c('el-form-item',{attrs:{"label":"上传视频：","rules":[
          {
            required: true,
            trigger: 'blur',
          } ]}},[_c('el-upload',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"avatar-uploader",attrs:{"action":_vm.baseURL + '/tea-admin/tea/video/uploadVideo',"show-file-list":false,"on-success":_vm.handleAvatarSuccess,"on-change":_vm.uploadVideoChange,"before-upload":_vm.beforeAvatarUpload}},[(_vm.imageUrl)?_c('video',{staticClass:"avatar",staticStyle:{"backgroundcolor":"black"},attrs:{"src":_vm.imageUrl,"controls":"controls","autoplay":"","muted":"","loop":""},domProps:{"muted":true}}):_c('i',{staticClass:"el-icon-plus avatar-uploader-icon"}),_c('div',{staticClass:"el-upload__tip",attrs:{"slot":"tip"},slot:"tip"},[_vm._v(" 支持的文件类型包括：mp4，ogv "),_c('p',[_vm._v("视频大小不超过2GB")])])]),(_vm.progressFlag)?_c('el-progress',{staticStyle:{"width":"50%"},attrs:{"percentage":_vm.loadProgress,"status":_vm.loadProgress==100?'success':''}}):_vm._e()],1):_c('el-form-item',{attrs:{"label":"填写设备号：","rules":[
          {
            required: true,
            trigger: 'blur',
          } ]}},[_c('el-input',{staticStyle:{"width":"19%"},attrs:{"placeholder":"设备号"},model:{value:(_vm.form.cameraCode),callback:function ($$v) {_vm.$set(_vm.form, "cameraCode", $$v)},expression:"form.cameraCode"}})],1)],1)],1),_c('div',{staticClass:"footer",attrs:{"slot":"footer"},slot:"footer"},[_c('button',{on:{"click":_vm.transferToManager}},[_vm._v("保存")])])])}
var staticRenderFns = []

export { render, staticRenderFns }