import {  post,get } from '../axiosconfig/axiostool'
//import 需要哪些引入哪些，我这里只需要post
export default {
//登录
  login(param){
    return post('/tea-admin/sys/login',param);
  },
  //退出登录
  logout(param){
    return post('/tea-admin/logout',param);
  },
//  出产量
  downloadTemplate(param){
    return get('/tea-admin/tea/productionAmount/downloadTemplate',param);
  },
  productionAmountImport(param){
    return post('/tea-admin/tea/productionAmount/productionAmountImport',param);
  },
  productionAmountList(param){
    return post('/tea-admin/tea/productionAmount/list',param);
  },
  //出货量
  saleAmountList(param){
    return post('/tea-admin/tea/saleAmount/list',param);
  },
  //销售价格
  salePriceList(param){
    return post('/tea-admin/tea/salePrice/list',param);
  },
  //茶叶新闻
  newsList(param){
    return post('/tea-admin/tea/news/list',param);
  },
  newsSave(param){
    return post('/tea-admin/tea/news/save',param);
  },
  //获取视频
  queryVideo(param){
    return post('/tea-admin/tea/video/queryVideo',param);
  },
  //保存视频
  videoSave(param){
    return post('/tea-admin/tea/video/save',param);
  },
  //删除新闻
  newsDelete(param){
    return post('/tea-admin/tea/news/delete',param);
  },
  // 获取农事管理信息
  getFarmInfo(){
    return post('/tea-admin/tea/video/getFarmInfo');
  },
  saveFarmInfo(param){
    return post('/tea-admin/tea/video/saveFarmInfo',param);
  },
  productionAmountExport(param){
    return post('/tea-admin/tea/productionAmount/productionAmountExport',param);
  },
}