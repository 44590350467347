<!--  -->
<template>
  <div class="tea">
    <p class="addP"><button @click="contactManger">新增</button></p>
    <tab-com :tableData="tableData" ref="tab" :thArr="thArr" :resource="'tea'"></tab-com>
    <div class="table-block">
      <el-pagination
        v-show="tableData.length"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="obj.pageNum"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="obj.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <el-dialog
      title="新建"
      :visible.sync="managerStatus"
      class="managerPopup"
      top="15%"
    >
      <div class="dialog-content">
        <el-form ref="form" :model="form" label-width="100px">
          <el-form-item label="新闻标题：" :rules="[
              {
                required: true,
                trigger: 'blur',
              },
            ]">
            <el-input
              v-model="form.title"
              placeholder="请输入新闻标题"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="链接："
            :rules="[
              {
                required: true,
                trigger: 'blur',
              },
            ]"
          >
            <el-input
              v-model="form.link"
              placeholder="请输入链接内容"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer" style="text-align:center">
        <el-button @click="managerStatus = false">取 消</el-button>
        <el-button type="primary" @click="transferToManager">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import tabCom from "../components/tabCom";
export default {
  data() {
    return {
      tabArr: ["出产量", "出货量", "销售价格"],
      tabActiveIndex: 0,
      tableData: [
        { date: "2022年12月15日", proKg: 123, time: "2020-12-07 00:00" },
        { date: "2022年12月15日", proKg: 123, time: "2020-12-07 00:00" },
        { date: "2022年12月15日", proKg: 123, time: "2020-12-07 00:00" },
        { date: "2022年12月15日", proKg: 123, time: "2020-12-07 00:00" },
        { date: "2022年12月15日", proKg: 123, time: "2020-12-07 00:00" },
        { date: "2022年12月15日", proKg: 123, time: "2020-12-07 00:00" },
        { date: "2022年12月15日", proKg: 123, time: "2020-12-07 00:00" },
      ],
      thArr: ["新闻标题", "新闻链接", "新建时间"],
      loading: false,
      obj: {
        pageNum: 1,
        pageSize: 10,
      },
      total: 0,
      managerStatus: false,
      form: {
        title: "",
        link: "",
      },
    };
  },
  mounted() {
    this.getNewsList()
  },
  components: {
    tabCom,
  },
  methods: {
    handleSizeChange(val) {
      this.obj.pageNum = 1
      this.obj.pageSize = val;
      this.$refs.tab.loading = true;
      this.getNewsList();
    },
    handleCurrentChange(val) {
      this.obj.pageNum = val;
      this.$refs.tab.loading = true;
      this.getNewsList("page");
    },
    contactCancle() {
      this.managerStatus = false;
      console.log("contactCancle");
    },
    contactConfirm() {
      console.log("contactConfirm");
      this.managerStatus = false;
    },
    contactManger() {
      this.managerStatus = true;
      console.log("managerStatus");
    },
    transferToManager(){
     
     if(this.form.title==''||this.form.link==''){
       this.$message.error('链接或标题不能为空')
     }else{
       this.$api.newsSave({
       title:this.form.title,
       link:this.form.link
     }).then(res=>{
       console.log(res)
       this.getNewsList( "page")
     })
     }
      this.managerStatus = false
      this.form.title=''
      this.form.link=''
     
    },
    getNewsList(type = "page") {
      if (type != "page") {
        this.obj.pageNum = 1;
      }
      console.log("getList");
      this.loading = true;
      this.$api.newsList({
          limit: this.obj.pageSize,
          page: this.obj.pageNum,
        })
        .then((res) => {
          console.log(res);
          this.$refs.tab.loading = false;
          this.total = +res.data.total;
          this.tableData = res.data.records;
        });
    },
  },
};
</script>
<style lang="scss">
li {
  list-style: none;
}
.head {
  width: 95%;
  height: 60px;
  line-height: 60px;
  display: flex;
  justify-content: flex-start;
  font-size: 16px;
  border-bottom: 1px solid #f0f0f0;
  color: #333333;
  margin: 0 auto;
}
.head li {
  padding: 0 20px;
  margin-right: 48px;
  cursor: pointer;
}
.tabActiveClass {
  border-bottom: 2px solid #1472ff;
}

.table-block {
  text-align: center;
  margin-top: 25px;
  background-color: #ffffff;
  height: 80px;
  position: fixed;
  bottom: 0;
  width: 70%;
}
.el-table .el-table__header-wrapper th,
.el-table .el-table__fixed-header-wrapper th {
  background-color: #f8f8f9 !important;
}
.addP {
  text-align: right;
  padding: 20px 20px 0 0;
}
.addP button {
  width: 80px;
  height: 30px;
  background: rgba(0, 128, 255, 1);
  border: 1px solid #0080ff;
  font-size: 14px;
  border: none;
  color: #ffffff;
  border-radius: 2px;
}
.el-dialog{
  width: 35%;
}
.managerPopup {
  .dialog-content {
    padding: 0 30px;
  }
}
.surePopup {
  /deep/ .dialog-content {
    text-align: center;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.managerPopup {
  .dialog-content {
    padding: 0 30px;
  }
}
.el-input{
  width: 300px;
}
</style>
