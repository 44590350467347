<!--  -->
<template>
  <div class="page">
    <div class="header">
      <ul>
        茶园驾驶舱后台管理
      </ul>
      <ul>
        <li
          v-for="(v, i) in tabArr"
          :key="i"
          :class="selectIndex == i ? 'activeClass' : ''"
          @click="changeActive(i)"
        >
          {{ v.name }}
        </li>
      </ul>
      <ul>
        <span>user</span>
        <el-dropdown @command="handleCommand">
          <span class="el-dropdown-link">
            admin<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="a">退出登录</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </ul>
    </div>
    <div class="content">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      selectIndex: 0,
      tabArr: [
        { name: "产销数据", router: "/manger/produce" },
        { name: "生长视频", router: "/manger/live" },
        { name: "茶叶新闻", router: "/manger/tea" },
        { name: "农事管理", router: "/manger/agriculture" },
      ],
    };
  },
  mounted() {
    
    
    for (let i in this.tabArr) {
      if (this.tabArr[i].router == this.$router.history.current.path) {
        this.selectIndex = i;
      }
    }
  },
  methods: {
    changeActive(i) {
      this.selectIndex = i;
      this.$router.push(this.tabArr[i].router);
    },
    handleCommand() {
      
      this.$message("退出登录成功 " );
      this.$router.push('/')
    },
  },
};
</script>
<style scoped>
.page {
  background: rgba(245, 248, 250, 0.39);
  width: 100%;
  height: 937px;
}
li {
  list-style: none;
}
.header {
  width: 95%;
  padding: 0 2.5%;
  height: 64px;
  line-height: 64px;
  background: rgba(58, 71, 84, 1);
  display: flex;
  justify-content: space-between;
}
.header ul:first-of-type {
  font-size: 24px;
  font-family: 汉仪菱心体简;

  color: #ffffff;
}
.header ul:nth-of-type(2) {
  width: 40%;
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  font-family: PingFang SC;

  color: rgba(255, 255, 255, 0.8);
}
.header ul:nth-of-type(2) li {
  cursor: pointer;
}
.activeClass {
  border-bottom: 4px solid #0080ff;
}
.header ul:nth-of-type(3) {
  color: #ffffff;
  display: flex;
  justify-content: space-between;
}
.header ul:nth-of-type(3)>span:nth-of-type(1) {
  display: inline-block;
  text-align: center;
  line-height: 36px;
  width: 36px;
  height: 36px;
  background: rgba(0, 176, 255, 0.2);
  border-radius: 50%;
  font-size: 12px;
  margin-top: 15px;
}
.header ul:nth-of-type(3) span:nth-of-type(2) {
  font-size: 16px;
  margin: 0 15px;
  color: #ffffff;
}
.content {
  width: 70%;
  height: 853px;
  margin: 20px auto 0;
  background-color: #fff;
}
.el-dropdown-link {
  cursor: pointer;
  color: #409eff;
}
.el-icon-arrow-down {
  font-size: 12px;
}
.el-dropdown-link{
  color: #fff;
  margin-left: 10px;
}
</style>
