<!--  -->
<template>
  <div class="live">
    <div class="nav">生长视频</div>
    <div class="vd">
      <el-form ref="form" :model="form" label-width="130px">
        <el-form-item
          label="选择展示类型："
          :rules="[
            {
              required: true,
              trigger: 'blur',
            },
          ]"
        >
          <el-select v-model="form.type" placeholder="请选择展示类型">
            <el-option label="视频" value="视频"></el-option>
            <el-option label="直播" value="直播"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          v-if="form.type == '视频'"
          label="上传视频："
          :rules="[
            {
              required: true,
              trigger: 'blur',
            },
          ]"
        >
          <el-upload
            v-loading="loading"
            class="avatar-uploader"
            :action="baseURL + '/tea-admin/tea/video/uploadVideo'"
            :show-file-list="false"
            
            :on-success="handleAvatarSuccess"
            :on-change="uploadVideoChange"
            :before-upload="beforeAvatarUpload"
          >
            <video
              v-if="imageUrl"
              :src="imageUrl"
              class="avatar"
              controls="controls"
              style="backgroundcolor: black"
              autoplay
              muted
              loop
            />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            <div class="el-upload__tip" slot="tip">
              支持的文件类型包括：mp4，ogv
              <p>视频大小不超过2GB</p>
            </div>
          </el-upload>

          <el-progress
          style="width:50%"
            v-if="progressFlag"
            :percentage="loadProgress"
            :status="loadProgress==100?'success':''"
          ></el-progress>
        </el-form-item>
        <el-form-item
          label="填写设备号："
          :rules="[
            {
              required: true,
              trigger: 'blur',
            },
          ]"
          v-else
        >
          <el-input
            v-model="form.cameraCode"
            placeholder="设备号"
            style="width: 19%"
          ></el-input>
        </el-form-item>
      </el-form>
    </div>
    <div slot="footer" class="footer">
      <button @click="transferToManager">保存</button>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      form: {
        type: "视频",
        cameraCode: "",
      },
      imageUrl: "",
      loading: false,
      saveType: 0,
      baseURL: "",
      loadProgress: 0, // 动态显示进度条
      progressFlag: false, // 关闭进度条
    };
  },
  created() {
    this.baseURL = axios.defaults.baseURL;
  },
  mounted() {
    this.form.type = sessionStorage.getItem("type") || "视频";
    this.$api
      .queryVideo({
        type: this.saveType,
      })
      .then((res) => {
        console.log(res);
        this.imageUrl = res.data.url;
        this.form.cameraCode = res.data.cameraCode;
      });
  },
  methods: {
    handleAvatarSuccess(res) {
      this.imageUrl = res.data;
      console.log(this.imageUrl);
      this.loading = false;
    },
    beforeAvatarUpload(file) {
      console.log(file);
      
      const type = (file.type === "video/mp4"||file.type === 'video/ogg');
      const isLt2M = file.size / 1000 / 1000 / 1000 < 2;

      if (!type) {
        this.$message.error("上传文件只能是视频格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传视频大小不能超过 2G!");
      }
      this.loading = type&&isLt2M;
      return type&&isLt2M;
    },
    transferToManager() {
      console.log(this.form.type, this.form.cameraCode);
      sessionStorage.setItem("type", this.form.type);
      if (this.form.type == "视频" && this.imageUrl == "") {
        this.$message.error("内容不能为空");
      } else if (this.form.type == "直播" && this.form.cameraCode == "") {
        this.$message.error("设备号不能为空");
      } else {
        if (this.form.type == "视频") {
          this.saveType = 0;
        } else {
          this.saveType = 1;
        }
        this.$api
          .videoSave({
            type: this.saveType,
            url: this.imageUrl,
            cameraCode: this.form.cameraCode,
          })
          .then((res) => {
            console.log(res);
            if (res.code == 200) {
              this.$message.info(res.msg);
            } else {
              this.$message.error(res.msg);
            }
          });
      }
    },
    uploadVideoChange(file){
      if(file.status==='ready'){
        this.loadProgress=0
        this.progressFlag = true
        const interval=setInterval(()=>{
          if(this.loadProgress>=99){
            clearInterval(interval)
            return
          }
          this.loadProgress+=1
        },20)
      }
      if(file.status==='success'){
        this.loadProgress=100
        setTimeout(() => {
          this.progressFlag = false;
        }, 1000);
      }
    },
    uploadVideoProcess(event) {
      console.log(event)
      this.progressFlag = true; // 显示进度条
      this.loadProgress = parseInt(event.percent); // 动态获取文件上传进度
      if (this.loadProgress >= 100) {
        this.loadProgress = 100;
        setTimeout(() => {
          this.progressFlag = false;
        }, 1000); // 一秒后关闭进度条
      }
    },
  },
};
</script>
<style scoped>
.live {
  position: relative;
}
.nav {
  width: 95%;
  height: 60px;
  line-height: 60px;
  margin: 0 auto;
  border-bottom: 1px solid #e8e8e8;
  font-size: 16px;
  color: #333333;
  font-weight: bold;
}
.vd {
  width: 95%;
  margin: 30px auto;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  border: 1px solid #e5e7eb;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.footer {
  width: 70%;
  height: 60px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.1);
  position: fixed;
  bottom: 0;
  left: 15%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.footer button {
  width: 120px;
  height: 36px;
  background: rgba(0, 128, 255, 1);
  opacity: 1;
  border-radius: 2px;
  font-size: 14px;
  border: none;
  color: #ffffff;
}
</style>