<!--  -->
<template>
  <div class="produce">
    <ul class="head">
      <li
        v-for="(v, i) in tabArr"
        :key="i"
        :class="tabActiveIndex == i ? 'tabActiveClass' : ''"
        @click="changeTabActive(i)"
      >
        {{ v }}
      </li>
    </ul>
    <p class="addP">
      <el-button type="primary" plain @click="exportData" size="small"
        >导出数据</el-button
      ><el-button type="primary" @click="contactManger" size="small"
        >导入数据</el-button
      >
    </p>
    <tab-com
      :tableData="tableData"
      ref="tab"
      :thArr="thArr[tabActiveIndex]"
    ></tab-com>
    <div class="table-block">
      <el-pagination
        v-show="tableData.length"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="obj.pageNum"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="obj.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <el-dialog
      title="导入"
      :visible.sync="managerStatus"
      width="30%"
      class="managerPopup"
      top="15%"
    >
      <div class="dialog-content">
        <span>上传文件：</span>
        <el-upload
          ref="uploadFile"
          class="upload-demo"
          :action="actionUrl"
          :on-change="handleChange"
          :file-list="fileList"
          :show-file-list="true"
          :before-upload="beforeUpload"
          :on-success="successUpdate"
          :auto-upload="false"
        >
          <el-button size="small" icon="el-icon-upload2">点击上传</el-button>
        </el-upload>
        <a @click="downLoad" :href="downLoadSrc" download charset="UTF-8"
          >下载excel模板文件</a
        >
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="managerStatus = false">取 消</el-button>
        <el-button type="primary" @click="transferToManager">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog
      title="请选择日期范围"
      :visible.sync="exportStatus"
      width="30%"
      class="managerPopup"
      top="15%"
    >
      <div class="dialog-content">
        <el-date-picker
          v-model="timeRange"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          unlink-panels
          value-format="yyyy-MM-dd"
        >
        </el-date-picker>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="exportStatus = false">取 消</el-button>
        <el-button type="primary" @click="exportSubmit">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import tabCom from "../components/tabCom";
import axios from "axios";
// import {getToken} from 'js-cookie'
export default {
  data() {
    return {
      tabArr: ["采摘数量", "产茶数量", "销售数量"],
      tabActiveIndex: 0,
      tableData: [
        { date: "2022年12月15日", proKg: 123, time: "2020-12-07 00:00" },
        { date: "2022年12月15日", proKg: 123, time: "2020-12-07 00:00" },
        { date: "2022年12月15日", proKg: 123, time: "2020-12-07 00:00" },
        { date: "2022年12月15日", proKg: 123, time: "2020-12-07 00:00" },
        { date: "2022年12月15日", proKg: 123, time: "2020-12-07 00:00" },
        { date: "2022年12月15日", proKg: 123, time: "2020-12-07 00:00" },
        { date: "2022年12月15日", proKg: 123, time: "2020-12-07 00:00" },
      ],
      thArr: [
        ["日期", "采摘数量(斤)", "导入时间"],
        ["日期", "采摘数量(斤)", "导入时间"],
        [
          "日期",
          "安顶云雾茶(斤)",
          // "拔山绿茶(元/斤)",
          // "龙井绿茶(元/斤)",
          "导入时间",
        ],
      ],
      obj: {
        pageNum: 1,
        pageSize: 10,
      },
      total: 0,
      managerStatus: false,
      form: {
        phone: "",
        desc: "",
      },
      fileList: [],
      baseURL: "",
      downLoadSrc: "",
      actionUrl: "",
      timeRange: "",
      exportStatus: false,
    };
  },
  created() {
    this.baseURL = axios.defaults.baseURL;
    this.downLoadSrc =
      this.baseURL + "/tea-admin/tea/productionAmount/downloadTemplate";
    this.actionUrl =
      this.baseURL + "/tea-admin/tea/productionAmount/productionAmountImport";
  },
  mounted() {
    this.getAmountList();
  },
  components: {
    tabCom,
  },
  methods: {
    handleSizeChange(val) {
      this.obj.pageNum = 1;
      this.obj.pageSize = val;
      this.$refs.tab.loading = true;
      this.getAmountList("page");
    },
    handleCurrentChange(val) {
      this.obj.pageNum = val;
      this.$refs.tab.loading = true;
      this.getAmountList("page");
    },
    contactManger() {
      this.managerStatus = true;
      console.log("managerStatus");
      if (this.$refs.uploadFile) {
        this.$refs.uploadFile.clearFiles();
      }
    },
    contactCancle() {
      this.managerStatus = false;
      console.log("contactCancle");
    },
    contactConfirm() {
      console.log("contactConfirm");
      this.managerStatus = false;
    },

    transferToManager() {
      console.log("post", this.$refs.uploadFile.$children[0]);
      this.$refs.uploadFile.$children[0].post(
        this.$refs.uploadFile.fileList[0].raw
      );
      this.managerStatus = false;
    },
    exportData() {
      this.exportStatus = true;
      console.log("exportStatus");
      this.timeRange = "";
    },

    exportSubmit() {
      this.exportStatus = false;
      if (this.timeRange) {
        console.log(this.timeRange);
        var btn = document.createElement("a");
        switch (this.tabActiveIndex) {
          case 0:
            btn.setAttribute(
              "href",
              this.baseURL +
                "/tea-admin/tea/productionAmount/productionAmountExport?startTime=" +
                this.timeRange[0] +
                " 00:00:00" +
                "&endTime=" +
                this.timeRange[1] +
                " 23:59:59"
            ); // href链接
            break
          case 1:
            btn.setAttribute(
              "href",
              this.baseURL +
                "/tea-admin/tea/saleAmount/saleAmountExport?startTime=" +
                this.timeRange[0] +
                " 00:00:00" +
                "&endTime=" +
                this.timeRange[1] +
                " 23:59:59"
            ); // href链接
            break
             case 2:
            btn.setAttribute(
              "href",
              this.baseURL +
                "/tea-admin/tea/salePrice/salePriceExport?startTime=" +
                this.timeRange[0] +
                " 00:00:00" +
                "&endTime=" +
                this.timeRange[1] +
                " 23:59:59"
            ); // href链接
            break

        }

        btn.setAttribute("target", "_self");
        btn.click(); // 自执行点击事件
      }
    },

    handleChange(file, fileList) {
      this.fileList = fileList.slice(-3);
      console.log("file", file);
    },
    downLoad() {
      // axios.get(this.downLoadSrc).then((res) => {
      //   console.log(res);
      //   var btn = document.createElement("a");
      //   btn.setAttribute("download", ""); // download属性
      //   btn.setAttribute("href", this.downLoadSrc); // href链接
      //   btn.click(); // 自执行点击事件
      // });
    },
    beforeUpload(file) {
      console.log(file);

      let fileName = file.name;
      // let uid = file.uid
      let pos = fileName.lastIndexOf(".");
      let lastName = fileName.substring(pos, fileName.length);
      console.log(lastName, lastName.toLowerCase());
      if (
        lastName.toLowerCase() !== ".xls" &&
        lastName.toLowerCase() !== ".xlsx"
      ) {
        this.$message.error("文件必须为 .xls .xlsx类型");

        return false;
      }
      return false;
    },
    successUpdate(response) {
      console.log("successUpdate", response);
      if (response.code == 200) {
        this.$message(response.msg);
      } else {
        this.$message.warning(response.msg);
      }
      this.getAmountList();
    },
    getAmountList(type = "page") {
      if (type != "page") {
        this.obj.pageNum = 1;
      }
      console.log("getList");
      this.loading = true;
      if (this.tabActiveIndex == 0) {
        this.$api
          .productionAmountList({
            limit: this.obj.pageSize,
            page: this.obj.pageNum,
          })
          .then((res) => {
            console.log(res);
            this.$refs.tab.loading = false;
            this.total = +res.data.total;
            this.tableData = res.data.records;
          });
      } else if (this.tabActiveIndex == 1) {
        this.$api
          .saleAmountList({
            limit: this.obj.pageSize,
            page: this.obj.pageNum,
          })
          .then((res) => {
            console.log(res);
            this.$refs.tab.loading = false;
            this.total = +res.data.total;
            this.tableData = res.data.records;
          });
      } else {
        this.$api
          .salePriceList({
            limit: this.obj.pageSize,
            page: this.obj.pageNum,
          })
          .then((res) => {
            console.log(res);
            this.$refs.tab.loading = false;
            this.total = +res.data.total;
            this.tableData = res.data.records;
          });
      }
    },

    changeTabActive(i) {
      this.tabActiveIndex = i;
      this.obj.pageNum = 1;
      this.getAmountList();
      if (i == 0) {
        this.downLoadSrc =
          this.baseURL + "/tea-admin/tea/productionAmount/downloadTemplate";
        this.actionUrl =
          this.baseURL +
          "/tea-admin/tea/productionAmount/productionAmountImport";
      } else if (i == 1) {
        this.downLoadSrc =
          this.baseURL + "/tea-admin/tea/saleAmount/downloadTemplate";
        this.actionUrl =
          this.baseURL + "/tea-admin/tea/saleAmount/saleAmountImport";
      } else {
        this.downLoadSrc =
          this.baseURL + "/tea-admin/tea/salePrice/downloadTemplate";
        this.actionUrl =
          this.baseURL + "/tea-admin/tea/salePrice/salePriceImport";
      }
    },
  },
};
</script>
<style lang="scss" scoped>
li {
  list-style: none;
}
.head {
  width: 95%;
  height: 60px;
  line-height: 60px;
  display: flex;
  justify-content: flex-start;
  font-size: 16px;
  border-bottom: 1px solid #f0f0f0;
  color: #333333;
  margin: 0 auto;
}
.head li {
  padding: 0 20px;
  margin-right: 48px;
  cursor: pointer;
}
.tabActiveClass {
  border-bottom: 2px solid #1472ff;
}

.table-block {
  text-align: center;
  margin-top: 25px;
  background-color: #ffffff;
  height: 80px;
  position: fixed;
  bottom: 0;
  width: 70%;
}
.el-table .el-table__header-wrapper th,
.el-table .el-table__fixed-header-wrapper th {
  background-color: #f8f8f9 !important;
}

.surePopup {
  /deep/ .dialog-content {
    text-align: center;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.managerPopup {
  .dialog-content {
    padding: 0 30px;
    display: flex;
    justify-content: center;
    font-size: 14px;

    color: #333333;
  }
  .dialog-content span {
    margin-top: 5px;
    margin-right: 10px;
  }
  .dialog-content a {
    color: #1472ff;
    margin-left: 10px;
    cursor: pointer;
    text-decoration: none;
    margin-top: 7px;
  }
}
.el-dialog__footer {
  text-align: center;
}
</style>