<template>
  <div class="agriculture">
    <div class="nav">农事管理</div>
    <div class="vd">
      <el-form ref="form" :model="form" label-width="130px">
        <el-form-item
          v-for="(value, key) in form"
          :key="key"
          :label="label[key]"
          :rules="[
            {
              required: true,
              trigger: 'blur',
            },
          ]"
        >
          <el-input v-model="form[key]"></el-input>
          <span style="margin-left: 20px">次</span>
        </el-form-item>
      </el-form>
    </div>
    <div slot="footer" class="footer">
      <button @click="transferToManager">保存</button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        water: "",
        fix: "",
        farm: "",
        cut: "",
      },
      label: {
        water: "灌溉次数",
        fix: "修茸次数",
        farm: "施肥次数",
        cut: "除草次数",
      },
    };
  },
  mounted() {
    this.$api.getFarmInfo().then((res) => {
      console.log(res);
      this.form = res.data;
    });
  },
  methods: {
    transferToManager() {
      this.$api.saveFarmInfo(this.form).then((res) => {
        console.log(res);
        if (res.code == 200) {
          this.$message.info(res.msg);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>

<style>
.agriculture {
  position: relative;
}
.nav {
  width: 95%;
  height: 60px;
  line-height: 60px;
  margin: 0 auto;
  border-bottom: 1px solid #e8e8e8;
  font-size: 16px;
  color: #333333;
  font-weight: bold;
}
.vd {
  width: 95%;
  margin: 30px auto;
}
.footer {
  width: 70%;
  height: 60px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.1);
  position: fixed;
  bottom: 0;
  left: 15%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.footer button {
  width: 120px;
  height: 36px;
  background: rgba(0, 128, 255, 1);
  opacity: 1;
  border-radius: 2px;
  font-size: 14px;
  border: none;
  color: #ffffff;
}
</style>