<template>
  <div class="home">
    <img src="../assets/img/loginBg.png" alt="" class="BG" />
    <div class="box">
      <img src="../assets/img/kuangBg.png" alt="" class="kuangBg" />
      <div>
        <h2>茶园驾驶舱后台管理</h2>
        <el-input v-model="inputUser" placeholder="请输入账号" style="width:100%"></el-input>
        <el-input
          placeholder="请输入密码"
          v-model="inputPass"
          show-password
          style="width:100%"
        ></el-input>
        <button @click="goLogin">登录</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  name: "Home",
  data() {
    return {
      inputUser: "",
      inputPass: "",
    };
  },
  components: {},
  mounted(){
    console.log(axios.defaults.baseURL);
  },
  methods:{
    goLogin(){
      
      this.$api.login({
        username:this.inputUser,
        password:this.inputPass
      }).then(res=>{
        if(res.code==500){
          this.$message.error(res.msg)
        }else{
          this.$message(res.msg)
          this.$router.push('/manger')
        }
        
        
      })
      
      
    }
  }
};
</script>
<style lang="scss">
.BG {
  position: absolute;
  z-index: -2;
}
.kuangBg {
  position: absolute;
  z-index: -1;
}
.box {
  width: 510px;
  height: 560px;
  position: absolute;
  z-index: -1;
  top: 180px;
  right: 212px;
}
.box > div {
  width: 78%;
  margin: 0 auto;
  // background-color: red;
}
.box > div h2 {
  font-size: 30px;
  text-align: center;
  font-weight: bold;
  margin: 90px 0 40px;
  color: #ffffff;
}
.box > div button {
  width: 100%;
  height: 56px;
  background: rgba(128, 192, 255, 1);
  border: none;
  opacity: 1;
  border-radius: 8px;
  font-size: 24px;

  color: #ffffff;
}
// .el-input__inner{
//   height: 56px !important;
// }
.el-input:nth-of-type(2){
  margin: 36px 0 52px;
}
</style>
