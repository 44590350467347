<!--  -->
<template>
  <div class="message">
    <el-table
      v-loading="loading"

      :data="tableData"
      style="width: 100%"
      height="calc(100% - 32px)"
      :header-cell-style="{ fontFamily: 'YaHei' }"
    >
      <template slot="empty">
        <img
          v-if="!loading"
          width="200"
          src="../assets/img/noData.png"
          alt="暂无数据"
        />
        <p v-if="!loading" class="empty-text">暂无数据</p>
        <span></span>
      </template>
      <el-table-column prop="username" :label="thArr[0]">
        <template slot-scope="scope">
          {{ scope.row.timeStr||scope.row.title || "-" }}
        </template>
      </el-table-column>
      <el-table-column prop="mobile" :label="thArr[1]">
        <template slot-scope="scope">
          <a :href="scope.row.link" v-if="scope.row.link">{{scope.row.link}}</a>
          <p v-else>{{ scope.row.amount ||scope.row.ownPrice||0 }}</p>
        </template>
      </el-table-column>
      <el-table-column prop="roleName" :label="thArr[2]">
        <template slot-scope="scope">
          <!-- {{scope.row.competitorOne?scope.row.competitorOne:scope.row.competitorOne==0?'0':scope.row.createTime }} -->
           {{ scope.row.createTime || "-" }}
        </template>
      </el-table-column>
       <el-table-column prop="roleName" :label="thArr[3]"  v-if="thArr[3]">
        <template slot-scope="scope">
          {{scope.row.competitorTwo?scope.row.competitorTwo:scope.row.competitorTwo==0?'0':scope.row.createTime }}
        </template>
      </el-table-column>
       <!-- <el-table-column prop="roleName" :label="thArr[4]"  v-if="thArr[4]">
        <template slot-scope="scope">
          {{scope.row.competitorThree?scope.row.competitorThree:scope.row.competitorThree==0?'0':scope.row.createTime }}
        </template>
      </el-table-column> -->
      <el-table-column prop="roleName" :label="thArr[4]" v-if="thArr[4]">
        <template slot-scope="scope">
          {{ scope.row.createTime || "-" }}
        </template>
      </el-table-column>
        <el-table-column prop="roleName" :label="'操作'" v-if="resource=='tea'">
        <template slot-scope="scope">
          <el-button
              @click="del(scope.row)"
              type="text"
              size="small"
              class="deleteBtn font-size"
            >
              删除
            </el-button>
        </template>
      </el-table-column>
    </el-table>
    
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading:false
    };
  },
  props:['tableData','thArr','resource'],
  mounted() {},
  methods: {
     // 删除
    del(data) {
      console.log(data,"data")
      this.$confirm("您确认是否删除此项内容?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        customClass: "confirm",
      }).then(() => {
        
        this.$api.newsDelete({
          ids:data.id
        }).then(res=>{
          console.log(res)
          this.$parent.getNewsList()
        })
        // this.$http.automaticReply.deleteData(id).then((res) => {
        //   this.$message({
        //     type: "success",
        //     message: "删除成功!",
        //   });
        //   this.getCodeList();
        // });
      });
    },
  },
};
</script>
<style>
  .message {
  padding: 20px 19px;
  height: 650px;
  border: none;
}
a{
  text-decoration: none;
}
.el-table .el-table__cell {
  text-align: left !important;
  padding: 20px;
}
</style>
