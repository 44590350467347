var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tea"},[_c('p',{staticClass:"addP"},[_c('button',{on:{"click":_vm.contactManger}},[_vm._v("新增")])]),_c('tab-com',{ref:"tab",attrs:{"tableData":_vm.tableData,"thArr":_vm.thArr,"resource":'tea'}}),_c('div',{staticClass:"table-block"},[_c('el-pagination',{directives:[{name:"show",rawName:"v-show",value:(_vm.tableData.length),expression:"tableData.length"}],attrs:{"current-page":_vm.obj.pageNum,"page-sizes":[10, 20, 50, 100],"page-size":_vm.obj.pageSize,"layout":"total, sizes, prev, pager, next, jumper","total":_vm.total},on:{"size-change":_vm.handleSizeChange,"current-change":_vm.handleCurrentChange}})],1),_c('el-dialog',{staticClass:"managerPopup",attrs:{"title":"新建","visible":_vm.managerStatus,"top":"15%"},on:{"update:visible":function($event){_vm.managerStatus=$event}}},[_c('div',{staticClass:"dialog-content"},[_c('el-form',{ref:"form",attrs:{"model":_vm.form,"label-width":"100px"}},[_c('el-form-item',{attrs:{"label":"新闻标题：","rules":[
            {
              required: true,
              trigger: 'blur',
            } ]}},[_c('el-input',{attrs:{"placeholder":"请输入新闻标题"},model:{value:(_vm.form.title),callback:function ($$v) {_vm.$set(_vm.form, "title", $$v)},expression:"form.title"}})],1),_c('el-form-item',{attrs:{"label":"链接：","rules":[
            {
              required: true,
              trigger: 'blur',
            } ]}},[_c('el-input',{attrs:{"placeholder":"请输入链接内容"},model:{value:(_vm.form.link),callback:function ($$v) {_vm.$set(_vm.form, "link", $$v)},expression:"form.link"}})],1)],1)],1),_c('div',{staticClass:"dialog-footer",staticStyle:{"text-align":"center"},attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":function($event){_vm.managerStatus = false}}},[_vm._v("取 消")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.transferToManager}},[_vm._v("确 定")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }