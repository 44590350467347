import Vue from "vue";
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from 'axios'
import VueAxios from 'vue-axios'
Vue.use(ElementUI);
Vue.use(VueAxios, axios)
Vue.config.productionTip = false;
// 统一接口地址 访问地址
import api from './service/apiconfig/'
Vue.prototype.$api=api
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
